<template>
  <div class="kinescope-video-constraint">
    <div ref="wrap" class="kinescope-video-wrap" />
  </div>
</template>

<script setup>
import { getPlayerFactory } from '~/utils/kinescope'

const props = defineProps(['videoId'])

let iframeId = 0

const wrap = ref(null)

watch([wrap, props], async ([wrap, props]) => {
  if (!wrap) return
  for (const child of wrap.children) {
    wrap.removeChild(child)
  }

  const iframe = document.createElement('iframe')
  iframe.id = `kvid-${++iframeId}`
  iframe.className = 'kinescope-video'
  iframe.frameBorder = '0'
  iframe.oncontextmenu = e => e.stopImmediatePropagation()
  wrap.appendChild(iframe)

  const playerFactory = await getPlayerFactory()
  playerFactory.create(iframe.id, {
    url: `https://kinescope.io/embed/${props.videoId}`,
    ui: { language: 'ru', header: false }
  })
}, { immediate: true })
</script>

<style>
.kinescope-video-constraint {
  max-width: calc(100vh + 300px);
}

.kinescope-video-wrap {
  position: relative;
  padding-top: 56.25%;
  border-radius: 8px;
  overflow: hidden;
}

.kinescope-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
