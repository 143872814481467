<template>
  <div class="course-content">
    <h1>{{ lesson.name }}</h1>
    <ContentRenderer :content="lesson.content" v-if="lesson.content" />

    <p><span class="dnc-error-msg">{{ errors['general'] }}</span></p>

    <p v-if="lesson.test_result">
      {{ user.is_mentor ? 'Менти набрал' : 'Вы набрали' }}
      {{ lesson.test_result.score }}
      {{ declOfNum(lesson.test_result.score, ['балл', 'балла', 'баллов']) }}
      из {{ lesson.test_result.max_score }}
    </p>
  </div>

  <form action="" @submit.prevent="submitTest">
    <div class="school-outline-card question-card course-content" :class="{
      'question-card--with-image': question.attachments.length === 1
    }" v-for="question of questions" :key="question.id">
      <div class="question-card__main-image" v-if="question.attachments.length === 1">
        <template v-for="(attachment, i) in question.attachments" :key="i">
          <img :src="attachment.entity.file.url" alt="" :key="attachment.entity.file.id"
               v-if="attachment.entity_type === 'image_attachment'" />
        </template>
      </div>
      <div>
        <small class="question-number">Вопрос {{ question.idx }}</small>
        <h2 class="question-title">{{ question.name }}</h2>

        <p class="question-subtitle" v-if="question.type === 'one'">Один вариант ответа</p>
        <p class="question-subtitle" v-else-if="question.type === 'many'">Можно выбрать несколько вариантов</p>
        <p class="question-subtitle" v-else-if="question.type === 'compare'">Соотнесите варианты ответа</p>
        <p class="question-subtitle" v-else-if="question.type === 'text'">Свободный ответ</p>

        <div class="question-images" v-if="question.attachments.length > 0">
          <template v-for="(attachment, i) in question.attachments" :key="i">
            <img class="question-image"
                 :src="attachment.entity.file.url" alt="" :key="attachment.entity.file.id"
                 v-if="attachment.entity_type === 'image_attachment'" />
          </template>
        </div>

        <template v-if="question.type === 'one' || question.type === 'many'">
          <div class="question-check-wrap" :class="{ 'dnc-custom-style-8': question.type === 'one' }" v-for="answer of question.answers" :key="answer.id">
            <div class="dnc-inputs__submit" :class="{
            'answer-correct': answer.correct === true,
            'answer-incorrect': answer.correct === false,
          }">
              <label class="dnc-submit__checkbox">
                <input type="radio" :value="answer.id" v-model="form[question.id].answer_id" :disabled="locked" v-if="question.type === 'one'">
                <input type="checkbox" :value="answer.id" v-model="form[question.id].answer_ids" :disabled="locked" v-else>
                <span class="dnc-checkbox__checkmark"></span>
                {{ answer.name }}
              </label>
            </div>
          </div>
          <span class="dnc-error-msg" v-if="question.type === 'one'">{{ errors[question.id+'.answer_id'] }}</span>
          <span class="dnc-error-msg" v-else>{{ errors[question.id+'.answer_ids'] }}</span>
        </template>

        <template v-else-if="question.type === 'compare'">
          <div class="form-field form-field--select" v-for="answer of question.answers" :key="answer.id">
            <label class="form-field__label" :for="`q${question.id}_${answer.id}`">
              {{ answer.name }}
            </label>

            <div class="form-field__select" :class="{
            'answer-correct': answer.correct === true,
            'answer-incorrect': answer.correct === false,
          }">
              <select class="form-field__input"
                      :id="`q${question.id}_${answer.id}`"
                      v-model="form[question.id].answer_comparisons[answer.id]" :disabled="locked">
                <option :value="undefined" disabled selected>Выберите</option>
                <option v-for="other of question.other_answers" :key="other" :value="other">{{ other }}</option>
              </select>
              <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.97055 9.50614C7.77408 9.50728 7.57931 9.46944 7.39742 9.39479C7.21553 9.32015 7.0501 9.21016 6.9106 9.07114L0.939046 3.07114C0.799851 2.93128 0.689436 2.76524 0.614105 2.58251C0.538773 2.39978 0.5 2.20393 0.5 2.00614C0.5 1.80835 0.538773 1.6125 0.614105 1.42976C0.689436 1.24703 0.799851 1.08099 0.939046 0.941137C1.07824 0.801279 1.24349 0.690337 1.42536 0.614647C1.60722 0.538956 1.80215 0.5 1.999 0.5C2.19585 0.5 2.39077 0.538956 2.57264 0.614647C2.75451 0.690337 2.91975 0.801279 3.05895 0.941137L7.97055 5.90614L12.8971 1.13614C13.0344 0.982702 13.2018 0.859389 13.3887 0.77391C13.5756 0.688431 13.7781 0.642625 13.9835 0.639356C14.1888 0.636087 14.3926 0.675425 14.5822 0.754911C14.7717 0.834398 14.9429 0.952323 15.085 1.10131C15.2271 1.2503 15.337 1.42714 15.408 1.62078C15.479 1.81442 15.5094 2.0207 15.4975 2.2267C15.4855 2.4327 15.4313 2.63401 15.3384 2.81802C15.2454 3.00204 15.1157 3.1648 14.9573 3.29614L8.98571 9.08614C8.7127 9.35061 8.34975 9.50077 7.97055 9.50614Z"
                  fill="#9E9E9E"/>
              </svg>
            </div>

            <div class="form-field__description answer-correct-label" v-if="answer.correct === true">
              <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.67587 5.48186C1.48741 5.31318 1.24183 5.22165 0.988394 5.22565C0.734961 5.22965 0.492419 5.32887 0.309421 5.50341C0.126422 5.67795 0.0165037 5.9149 0.00171272 6.16673C-0.0130783 6.41856 0.0683524 6.66665 0.229671 6.86122L2.45922 9.19013C2.55257 9.28758 2.66474 9.36527 2.78899 9.41851C2.91325 9.47176 3.04704 9.49947 3.18232 9.49998C3.31689 9.50076 3.45024 9.47462 3.57446 9.42311C3.69867 9.3716 3.81122 9.29577 3.90542 9.20012L10.7146 2.20339C10.8069 2.10889 10.8796 1.99721 10.9286 1.87473C10.9775 1.75226 11.0018 1.62138 10.9999 1.48959C10.998 1.35779 10.9701 1.22765 10.9177 1.10659C10.8653 0.985538 10.7895 0.875941 10.6945 0.784059C10.5995 0.692176 10.4873 0.619808 10.3643 0.571086C10.2412 0.522365 10.1097 0.498243 9.97728 0.5001C9.84486 0.501956 9.7141 0.529753 9.59246 0.581905C9.47083 0.634057 9.36071 0.709542 9.26839 0.804049L3.19236 7.06112L1.67587 5.48186Z" fill="#04BD86"/>
              </svg>
              <span>Верно</span>
            </div>
            <div class="form-field__description answer-incorrect-label" v-else-if="answer.correct === false">
              <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.27933 8.37179C-0.09311 8.74424 -0.0931098 9.34809 0.27933 9.72054C0.651769 10.093 1.25561 10.093 1.62805 9.72054L4.99994 6.34861L8.37195 9.72067C8.74439 10.0931 9.34823 10.0931 9.72067 9.72067C10.0931 9.34822 10.0931 8.74437 9.72067 8.37192L6.34866 4.99986L9.7204 1.62808C10.0928 1.25563 10.0928 0.651779 9.7204 0.279334C9.34795 -0.0931111 8.74411 -0.0931114 8.37167 0.279334L4.99994 3.65112L1.62833 0.279462C1.25589 -0.092983 0.652045 -0.0929828 0.279606 0.279462C-0.0928338 0.651907 -0.0928341 1.25576 0.279606 1.62821L3.65121 4.99986L0.27933 8.37179Z" fill="#F53669"/>
              </svg>
              <span>Неверно</span>
            </div>
          </div>
          <span class="dnc-error-msg">{{ errors[question.id+'.answer_comparisons'] }}</span>
        </template>

        <div class="form-field" v-else-if="question.type === 'text'" :class="{
        'answer-correct': question.correct === true,
        'answer-incorrect': question.correct === false,
      }">
          <label class="form-field__label" :for="`q${question.id}`">Ответ</label>
          <textarea class="form-field__input" name="question-body" :id="`q${question.id}`" v-model="form[question.id].answer_text" :disabled="locked" />
          <span class="dnc-error-msg">{{ errors[question.id+'.answer_text'] }}</span>
        </div>

        <template v-if="question.explain">
          <h3 class="question-explanation">Правильный ответ</h3>
          <p>{{ question.explain }}</p>
        </template>
      </div>
    </div>

    <div class="evq-container" v-if="!locked">
      <button type="submit" class="dnc-button evq-button-primary">
        Отправить
      </button>
    </div>
  </form>
</template>

<script setup>
import cloneDeep from 'lodash.clonedeep'
import ContentRenderer from '@/components/common/ContentRenderer'
import { shuffle } from '~/utils/shuffle'
import useForm from '~/composables/useForm'
import { sendTest } from '@/api/materials'
import { declOfNum } from '~/utils/format'

const props = defineProps(['lesson'])

const user = inject('user')
const updateLesson = inject('updateLesson')
const {
  form,
  errors,
  submit,
  loading
} = useForm(async data => {
  const testData = cloneDeep(data)
  for (const key of Object.keys(testData)) {
    const question = testData[key]
    if (question.type === 'compare') {
      question.answer_comparisons = Object.keys(question.answer_comparisons).reduce((acc, cur) => {
        acc.push([~~cur, question.answer_comparisons[cur]])
        return acc
      }, [])
    }
  }
  return sendTest(props.lesson.id, testData)
}, async () => {
  updateLesson(await updateLesson(false))
})
const questions = computed(() => {
  const testRun = props.lesson.test_result
  const allAnswers = testRun?.answers.reduce((acc, cur) => {
    if (!acc[cur.question_id]) acc[cur.question_id] = []
    acc[cur.question_id].push(cur)
    return acc
  }, {}) ?? {}
  const questions = cloneDeep(testRun?.questions ?? props.lesson.questions)

  return questions
    .sort((a, b) => a.order - b.order)
    .map((a, i) => ({ ...a, idx: i + 1 }))
    .map(question => {
      // General processing
      if (question.type === 'compare') {
        question.other_answers = question.answers.reduce((acc, cur) => {
          acc.push(cur.right_comparable)
          return acc
        }, [])
        shuffle(question.other_answers)
      }

      // Correct answer processing
      if (testRun) {
        const answers = allAnswers[question.id]
        if (question.answers?.length > 0) {
          question.answers = question.answers.map(answer => {
            const userAnswer = answers?.find(a => a.answer_id === answer.id)

            // Either 'one' or 'many'
            if (question.type !== 'compare') {
              // Right: marked as the correct one by test makers
              // Correct: answered correctly by the user
              if (userAnswer && answer.right) {
                answer.correct = true
              } else if (userAnswer || answer.right) {
                answer.correct = false
              } else {
                // If user hasn't chosen the answer and it's wrong, then the answer is correct,
                // but we don't mark it as such.
              }
            } else {
              // The type is 'compare'
              answer.correct = answer.right_comparable === userAnswer.comparison
            }
            return answer
          })
        } else if (question.exist_right_answer) {
          question.correct = question.right_text.toLowerCase() === answers?.[0].answer_text.toLowerCase()
        }
      }
      return question
    })
})
const locked = computed(() => !!props.lesson.test_result || user.value.is_mentor)

watch(() => props.lesson, value => {
  // This code either fills out a new form, or fills the form with
  // previous values if there is a previous test run.

  const testRun = value.test_result
  const answers = testRun?.answers.reduce((acc, cur) => {
    if (!acc[cur.question_id]) acc[cur.question_id] = []
    acc[cur.question_id].push(cur)
    return acc
  }, {}) ?? {}

  const questions = testRun?.questions ?? value.questions
  form.value = questions.reduce((qmap, q) => {
    qmap[q.id] = {
      question_id: q.id,
      type: q.type
    }
    if (q.type === 'compare') {
      qmap[q.id].answer_comparisons = answers[q.id]?.reduce((acc, cur) => {
        acc[cur.answer_id] = cur.comparison
        return acc
      }, {}) ?? {}
    } else if (q.type === 'many') {
      qmap[q.id].answer_ids = answers[q.id]?.map(a => a.answer_id) ?? []
    } else if (q.type === 'one') {
      qmap[q.id].answer_id = answers[q.id]?.[0].answer_id ?? null
    } else if (q.type === 'text') {
      qmap[q.id].answer_text = answers[q.id]?.[0].answer_text ?? ''
    } else {
      throw new Error('Unknown question type')
    }
    return qmap
  }, {})
}, { immediate: true })

function submitTest () {
  if (locked.value) return
  submit()
}
</script>

<style lang="scss" scoped>
.question-card {
  margin-bottom: 20px;

  &__main-image {
    display: none;
  }

  @media (min-width: 1250px) {
    &--with-image {
      display: flex;
      align-items: center;

      .question-images {
        display: none;
      }
    }

    &__main-image {
      display: block;
      margin-right: 32px;
      flex-basis: 40%;
      width: 0;

      img {
        width: 100%;
      }
    }
  }
}

.question-number {
  display: block;
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: bold;
}

.question-title {
  margin: 0;
  font-size: 24px;
}

.question-subtitle {
  color: var(--color-text-dimmed);
  font-size: 14px;
}

.question-images {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
}

.question-image {
  max-height: 300px;
  margin-right: 12px;
  margin-bottom: 12px;
}

.question-check-wrap {
  margin-bottom: 12px;
}

.dnc-inputs__submit {
  color: var(--color-text);
  text-transform: none;
}

.dnc-custom-style-8 .dnc-checkbox__checkmark::after {
  border: none;
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: var(--color-checkmark);
}

.form-field {
  margin-bottom: 16px;
}

.form-field__label {
  display: block;
  font-family: 'PF Din Text Cond Pro', sans-serif;
  font-size: 18px;
  color: var(--color-text-dimmed);
  text-transform: uppercase;
}

.form-field__input {
  display: block;
  width: 100%;
  padding: 10px 16px;
  font: inherit;
  font-family: Inter, sans-serif;
  border: 1px solid var(--color-secondary);
  border-radius: 10px;
  background: var(--color-bg);
  color: inherit;
}

textarea.form-field__input {
  resize: vertical;
  height: 80px;
  margin: 8px 0;
}

select.form-field__input {
  background: var(--color-test-select-background);
  border: 1px solid var(--color-school-outline-card-border);
  font-weight: 500;
  padding-right: 40px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.form-field__input:focus {
  outline-color: var(--color-accent);
}

.form-field__select {
  position: relative;
}

.form-field__select svg {
  position: absolute;
  top: 18px;
  right: 16px;
  opacity: .5;
}

.evq-button-primary {
  font-weight: 700;
  padding: 16px 24px 14px;
}

@media (min-width: 1200px) {
  .form-field--select {
    display: grid;
    grid-template-columns: minmax(180px, 1fr) minmax(300px, 2fr) 1fr;
    grid-column-gap: 16px;
  }

  .form-field__label {
    align-self: center;
    color: var(--color-text);
  }
}

.answer-correct, .answer-correct-label {
  --color: var(--color-green);
}
.answer-incorrect, .answer-incorrect-label {
  --color: var(--color-error);
}

.answer-correct, .answer-incorrect {
  input, select, textarea, svg {
    color: var(--color);
    border-color: currentColor;
  }

  svg {
    opacity: 1;
  }

  svg path {
    fill: currentColor;
  }

  .dnc-checkbox__checkmark {
    --color-primary: var(--color);
  }

  input:not(:checked) + .dnc-checkbox__checkmark {
    box-shadow: inset 0 0 0 1px var(--color);
    background: none;

    &::after {
      display: block;
      border-color: var(--color);
    }
  }

  input[type=radio]:not(:checked) + .dnc-checkbox__checkmark::after {
    background-color: var(--color);
  }

  label {
    color: var(--color);
  }
}

.answer-correct-label,
.answer-incorrect-label {
  color: var(--color);
  align-self: center;

  svg {
    margin-right: 9px;
  }
}

.question-explanation {
  margin: 24px 0 0;
  font-size: 18px;
}

.question-explanation + p {
  margin: 0;
}
</style>
