<template>
  <div class="course-content" v-if="lesson !== null">
    <KinescopePlayer :video-id="lesson.kinescope_video_id" v-if="lesson.kinescope_video_id" />
    <img class="school-content-hero-img" :src="lesson.image_url" v-else-if="lesson.image_url" alt="" />

    <h1>{{ lesson.name }}</h1>
    <p v-if="lesson.duration"><small>{{ lesson.duration }} {{ declOfNum(lesson.duration, ['минута', 'минуты', 'минут']) }}</small></p>

    <ContentRenderer :content="lesson.content" />

    <LessonHomework :homework="lesson.homework" v-if="lesson.homework" />
  </div>
</template>

<script setup>
import ContentRenderer from '@/components/common/ContentRenderer'
import LessonHomework from '@/components/mentor/materials/LessonHomework'
import KinescopePlayer from '@/components/mentor/materials/KinescopePlayer'
import { declOfNum } from '~/utils/format'

const props = defineProps(['lesson'])

const user = inject('user')
</script>
