let onLoaded, playerFactory

export function getPlayerFactory () {
  if (!onLoaded) {
    onLoaded = new Promise((resolve) => {
      window.onKinescopeIframeAPIReady = (_playerFactory) => {
        playerFactory = _playerFactory
        resolve(playerFactory)
      }
      const tag = document.createElement('script')
      tag.src = 'https://player.kinescope.io/latest/iframe.player.js'
      document.body.appendChild(tag)
    })
  }

  return onLoaded
}
