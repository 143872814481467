<template>
  <div class="school-outline-card course-content">
    <h2>Задание</h2>
    <ContentRenderer :content="homework.description" />

    <FileCard :file="homework.file" />

    <p v-if="homework.required">Выполнение обязательно для прохождения курса.</p>

    <h3 v-if="homework.answers.length > 0">Сданные работы</h3>
    <template v-for="answer in homework.answers" :key="answer.id">
      <hr />
      <FileCard :file="answer.file" v-if="answer.file" />
      <UserComment :outgoing="true" :text="answer.user_comment"
                     v-if="answer.user_comment" />
    </template>

    <template v-if="!user.is_mentor">
      <h3>Загрузить ответ</h3>
      <form class="file-upload" @submit.prevent="submit">
        {{ errors['general'] }}

        <div class="form-field form-field--file">
          <FileUploader label="Файл" v-model="form.file" :error="errors['file_id']" />
        </div>

        <div class="form-field">
          <BaseInput label="Комментарий" type="textarea" v-model="form.comment" :error="errors['comment']" />
        </div>

        <button type="submit" class="dnc-button dnc-button-secondary" :class="{ pending: loading }">
          Отправить
        </button>
      </form>
    </template>
  </div>
</template>

<script setup>
import BaseInput from '@/components/common/BaseInput'
import FileCard from '@/components/mentor/materials/FileCard'
import FileUploader from '@/components/common/FileUploader'
import { sendHomework } from '@/api/materials'
import useForm from '~/composables/useForm'
import ContentRenderer from '@/components/common/ContentRenderer'
import UserComment from '@/components/mentor/materials/UserComment'

const props = defineProps(['homework', 'lesson'])

const user = inject('user')
const updateLesson = inject('updateLesson')
const {
  form,
  errors,
  submit,
  loading
} = useForm(async data => sendHomework(props.lesson.id, data), async () => {
  await updateLesson()
  form.value = {}
})

const me = computed(() => ({ ...user.value.personal }))
</script>

<style lang="scss">
.file-upload {
  font-family: 'PF Din Text Cond Pro', sans-serif;

  .dnc-input__form {
    justify-content: flex-start;
  }

  .dnc-submit__checkbox {
    background-color: var(--color-school-outline-card-background);
  }

  .form-field {
    margin-bottom: 16px;

    .dnc-personal-info__input {
      display: block;

      .dnc-input__title {
        display: block;
        margin-bottom: 4px;
      }
    }

    textarea {
      width: 100%;
      height: 100px;
      resize: vertical;
      border-radius: 12px;
      padding: 16px 20px;
      font-family: Inter, sans-serif;
      color: var(--color-text);
      background-color: var(--color-bg);
      border: 1px solid var(--color-separator);
    }

    &--file {
      .dnc-submit__checkbox {
        max-width: 100% !important;
        cursor: pointer;
      }

      @media (min-width: 920px) {
        .dnc-personal-info__input {
          display: grid;
          grid-template-columns: 150px 1fr;
        }
      }
    }
  }
}
</style>
