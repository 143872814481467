<template>
  <template v-if="lesson !== null">
    <LessonNormal :lesson="lesson" v-if="lesson.type === 'lesson'" />
    <LessonTest :lesson="lesson" v-else-if="lesson.type === 'test'" />
    <LessonHomework :homework="lesson.homework" :lesson="lesson" v-else-if="lesson.type === 'homework'" />
    <p v-else>Неизвестный тип урока.</p>
  </template>
</template>

<script setup>
import { getLessonForMenti } from '@/api/materials'
import LessonTest from '@/components/mentor/materials/LessonTest'
import LessonHomework from '@/components/mentor/materials/LessonHomework'
import LessonNormal from '~/components/mentor/materials/LessonNormal'

const router = useRouter()
const route = useRoute()
const user = inject('user')
const lesson = ref(null)
const isMentor = computed(() => user.value.is_mentor)

const lessons = inject('lessons')

async function updateLesson (lessonData) {
  if (!lessonData) {
    const data = !isMentor.value ? await (getLessonForMenti(route.params.lessonId)) : lessons.value.find(l => l.lesson_id === +route.params.lessonId)
    if (lessonData === false) return data
    lessonData = data
  }
  if (!lessonData) throw createForbiddenError()
  if (lessonData) lesson.value = lessonData
  return lessonData
}
provide('updateLesson', updateLesson)

await updateLesson()
</script>
